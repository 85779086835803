var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.tasks,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":"","item-key":"_id","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.itemsPerPage > 10)?_c('v-row',{staticClass:"pl-3 pr-4",attrs:{"justify":"start","align":"start","width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","offset-md":"9","md":"3"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e()]},proxy:true},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getModel(item))+" ")]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_vm._v(" "+_vm._s(item._id)+" "),_c('v-icon',{style:({ visibility: hover ? 'visible': 'hidden' }),attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item._id)}}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)})]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.source ? item.source.replace('DSP_', '') : 'Mgid')+" ")]}},{key:"item.country_code",fn:function(ref){
var item = ref.item;
return [_c('flag',{staticClass:"mr-3",attrs:{"iso":item.country_code}})]}},{key:"item.@timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item['@timestamp']))+" ")]}},{key:"item.offer_name",fn:function(ref){
var item = ref.item;
return [(item.postfix)?_c('div',[_vm._v(" "+_vm._s(item['offer_name'] + ' ( ' + item.postfix + ' )'))]):_c('div',[_vm._v(" "+_vm._s(item['offer_name']))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }